import React from 'react';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from 'perfect-scrollbar';

// core components
import AdminNavbar from 'components/Navbars/AdminNavbar.js';
import Footer from 'components/Footer/Footer.js';
import Sidebar from 'components/Sidebar/Sidebar.js';
import FixedPlugin from 'components/FixedPlugin/FixedPlugin.js';

import routes from 'routes.js';

import logo from 'assets/img/react-logo.png';
import { BackgroundColorContext } from 'contexts/BackgroundColorContext';

import { useAuth } from "../../contexts/AuthContext.js";

var ps;

function Admin(props) {
  const { user } = useAuth();
  const location = useLocation();
  const mainPanelRef = React.useRef(null);
  const [sidebarOpened, setsidebarOpened] = React.useState(
    document.documentElement.className.indexOf('nav-open') !== -1
  );
  React.useEffect(() => {
    document.documentElement.className += ' perfect-scrollbar-on';
    document.documentElement.classList.remove('perfect-scrollbar-off');
    ps = new PerfectScrollbar(mainPanelRef.current, {
      suppressScrollX: true,
    });
    let tables = document.querySelectorAll('.table-responsive');
    for (let i = 0; i < tables.length; i++) {
      ps = new PerfectScrollbar(tables[i]);
    }
    // Specify how to clean up after this effect:
    return function cleanup() {
      ps.destroy();
      document.documentElement.classList.add('perfect-scrollbar-off');
      document.documentElement.classList.remove('perfect-scrollbar-on');
    };
  });
  React.useEffect(() => {
    let tables = document.querySelectorAll('.table-responsive');
    for (let i = 0; i < tables.length; i++) {
      ps = new PerfectScrollbar(tables[i]);
    }
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    if (mainPanelRef.current) {
      mainPanelRef.current.scrollTop = 0;
    }
  }, [location]);
  // this function opens and closes the sidebar on small devices
  const toggleSidebar = () => {
    document.documentElement.classList.toggle('nav-open');
    setsidebarOpened(!sidebarOpened);
  };
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === '/admin' && prop.type === user?.role) {
        return (
          <Route path={prop.path} element={prop.component} key={key} exact />
        );
      } else {
        return null;
      }
    });
  };
  const getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1) {
        return routes[i].name;
      }
    }
    return 'Brand';
  };
 
  return (
    <BackgroundColorContext.Consumer>
      {({ color, changeColor }) => (
        <React.Fragment>
          <div className='wrapper'>
            <Sidebar
              routes={routes}
              logo={{
                innerLink: "/admin/dashboard",
                text: "Blue Jay",
                imgSrc: logo,
              }}
              toggleSidebar={toggleSidebar}
            />
            <div className='main-panel' ref={mainPanelRef} data={color}>
              <AdminNavbar
                brandText={getBrandText(location.pathname)}
                toggleSidebar={toggleSidebar}
                sidebarOpened={sidebarOpened}
              />
              <Routes>
                {getRoutes(routes)}
                <Route
                  path='/'
                  element={<Navigate to='/admin/dashboard' replace />}
                />
              </Routes>
              {
                // we don't want the Footer to be rendered on map page
                location.pathname === '/admin/maps' ? null : <Footer fluid />
              }
            </div>
          </div>
          <FixedPlugin bgColor={color} handleBgClick={changeColor} />
        </React.Fragment>
      )}
    </BackgroundColorContext.Consumer>
  );
}

export default Admin;
